.auth_section .auth_navbar {
    height: 70px;
}

.auth_section .auth_navbar img {
    cursor: pointer;
}

.auth_section .login_area {
    padding-top: 150px;
}

.auth_section .login_area h2 {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 34px;
}

.auth_section .login_area input {
    background-color: transparent;
    border: 2px solid #9B99AF;
}

.auth_section .login_area input:hover {
    border: 2px solid #000000;
}

.auth_section .login_area .email_checkbox {
    width: 27px;
    height: 27px;
    box-sizing: border-box;
    border-radius: 0;
    outline: 0;
}

.auth_section .login_area input[type=checkbox] {
    accent-color: #dddddd;
    width: 27px;
    height: 27px;
    border-radius: 0;
    outline: 0;
    border: 2px solid #9B99AF;
}

/* .auth_section .login_area .email_checkbox_container{
    position: relative;
} */

.auth_section .login_area .email_checkbox_container label{
    margin-top: 4px;
}

.auth_section .login_area .email_checkbox_container input {
    width: 27px;
    height: 27px;
    border: 2px solid black;
    border-radius: 0%;
    outline: none;
}

.auth_section .login_area .email_checkbox_container input:checked {
    width: 27px;
    height: 27px;
    border: 2px solid black;
    border-radius: 0%;
}

.auth_section .login_area input[type=email]:focus,
.auth_section .login_area input[type=password]:focus,
.auth_section .login_area input[type=email]:active,
.auth_section .login_area input[type=password]:active,
.auth_section .login_area input[type=checkbox]:focus,
.auth_section .login_area input[type=checkbox]:active {
    border: 3px solid #000000;
    outline: 0px;
}

.auth_section .login_area button,
.auth_section .login_area input {
    border-radius: 4px;
    max-width: 400px;
}

.auth_section .login_area .forgot_password {
    display: flex;
    justify-content: space-between;
}

.auth_section .login_area .forgot_password p {
    cursor: pointer;
    margin-top: 0;
}

.auth_section .login_area .forgot_password p:hover {
    color: #4285F4
}

.auth_section .login_area .forgot_password button {
    background-color: transparent;
    border: none;
    margin-top: -17px;
    color: #EA4335;
}

.auth_section .login_area .forgot_password button:hover {
    color: #000000;
}

.auth_section .login_area .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth_section .login_area .actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #4285F4;
    border: 1px solid #4285F4;
    font-weight: 600;
    /* border-radius: 4px; */
    /* padding: 0.5rem 2.5rem; */
}

.auth_section .login_area .actions button:hover {
    background-color: #1970fc;
    border-color: #1970fc;
}

.auth_section .login_area form .toggle {
    margin-bottom: 1.4rem;
    font-size: 15px;
    font-weight: 600;
    background-color: transparent;
    border: none;
    color: #5797fd;
    padding: 0;
}

.auth_section .login_area form .toggle:hover {
    background-color: transparent;
    color: #1970fc;
}

.auth_section .google_auth button {
    display: block;
    width: 100%;
    padding: 0.5rem 2.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #9B99AF;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    justify-content: center;
    font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.auth_section .google_auth button:hover {
    background-color: #f0f0f0;
}

.auth_section .google_auth button svg {
    width: 28px;
    margin-right: 5px;
}

.auth_section .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.auth_section .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.auth_section .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

@media (min-width: 576px) {
    .auth_section .container {
        max-width: 540px;
    }

    .auth_section .container .row .col6_lg6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 768px) {
    .auth_section .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .auth_section .container {
        max-width: 960px;
    }

    .auth_section .container .row .col6_lg6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .auth_section .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .auth_section .container {
        max-width: 1320px;
    }
}