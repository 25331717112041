.formcontrol label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.formcontrol input {
    font: inherit;
    background-color: #f1e1fc;
    color: #000000;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.75rem;
    margin: 5px 0;
}

.formcontroltextarea textarea {
    font: inherit;
    background-color: #f1e1fc;
    color: #000000;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.75rem;
    margin: 5px 0;
    height: 200px;
}

.toggle {
    background-color: #EA4335;
    color: #FFFFFF;
    border: 1px solid #EA4335;
    width: 100%;
    text-align: center;
    padding: 0.25rem;
    padding: 0.75rem 1.5rem;
    margin: 5px 0;
    font-weight: 700;
    cursor: pointer;
}

.toggle:hover {
    background-color: #f05547;
}

/** Error  **/
.error_message {
    margin: 0 0 5px 0;
    color: #EA4335;
    font-size: 12px;
}